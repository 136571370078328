import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import SEO from '../../../components/SEO';
import Header from '../../../components/Header';
import ContentRow from '../../../components/ContentRow';


const headerColor = '#191514';

const BayPage = ({ data }) => (
  <>
    <SEO
      title="777Bay Neosurf Bonus Code – 133% Bonus &amp; 133 Free Spins"
      description="Exclusive 777Bay Casino bonus code for Neosurf payment option. Claim 133% First Deposit Bonus and 133 Free spins using a special Neosurf Code."
      alternateLanguagePages={[
        {
          path: 'casino/777bay',
          language: 'en'
        },
        {
          path: 'casino/777bay',
          language: 'fr'
        },
        {
          path: 'casino/777bay',
          language: 'de'
        }
      ]}
    />
    <Header backgroundColor={headerColor} />
    <ContentRow backgroundColor={headerColor} padV>
      <div className="grid c-2">
        <div>
          <a href="https://www.luckystar.io/?a_aid=5a6fb263dabe6&amp;a_bid=c6328648" target="_blank" rel="noopener noreferrer">
            <Img fluid={data.header.childImageSharp.fluid} alt="777Bay Casino" />
          </a>
        </div>
        <div>
          <div className="contentBox textAlignCenter">
            <h1 className="h3 smaller textColorLight">Neosurf &amp; Myneosurf Payment Methods at 777Bay Casino &amp; Sportsbook</h1>
            <p className="textColorLight">777Bay.com offers a wide range of payment methods, including standard card options, cryptocurrencies, digital wallets, and our favorites: Neosurf &amp; Myneosurf solutions. Quick, safe, and easy deposit options are just small fragments of 777Bay features. Check out a full review of <a href="https://www.getneosurf.com/blog/777bay">777Bay Casino</a>, and don't forget to claim an exclusive Neosurf Bonus Code below for your first deposit!</p>
          </div>
        </div>
      </div>
    </ContentRow>
    <div className="pageWidth">
      <div className="grid c-2 bigGap">
        <div>
          <div className="contentBox">
            <h2 className="smaller textUppercase">Exclusive 777Bay Neosurf Bonus Code</h2>
            <ul className="textColorDark">
              <li>133% First Deposit Bonus and 133 Free Spins</li>
              <li>USE THE CODE: NEO133</li>
              <li>The minimum deposit is: 20 €/$</li>
              <li>Wagering requirements: 30x (deposit+bonus)</li>
              <li>Max bet: 7,5 €/$</li>
              <li><a href="https://www.luckystar.io/content/terms-and-conditions">777Bay Terms &amp; Conditions</a></li>
            </ul>
            <p className="textAlignCenter">
              <a className="button" href="https://www.luckystar.io/?a_aid=5a6fb263dabe6&amp;a_bid=c6328648" target="_blank" rel="noopener noreferrer">TO 777 BAY CASINO</a>
            </p>
          </div>
        </div>
        <div>
          <Img fluid={data.bonuses.childImageSharp.fluid} alt="Exclusive 777Bay Neosurf Bonus Code" />
        </div>
      </div>
    </div>
  </>
)

export default BayPage;

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    header: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/777bay/777bay-accepts-Neosurf-deposit.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 899,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bonuses: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "casino/777bay/777bay-logo.jpg"}) {
      childImageSharp {
        fluid(
          maxWidth: 800,
          traceSVG: {
            color: "#ff009b"
          }
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
